import React, { useCallback, useState } from 'react';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import { getResults } from './data';

const Report = ({ data, onRestart }) => {
  const results = getResults(data);
  const isMonthlyPaymentTerm = data.paymentTerm.heading === 'Monthly';
  const effectiveRateHasDecimal =
    results.effectiveRate !== Math.floor(results.effectiveRate);
  const [animatedAmount, setAnimatedAmount] = useState(
    results.effectiveRate * 2
  );
  const [animationDone, setAnimationDone] = useState(false);
  // Allow animation to run for 2 seconds at 60fps
  const animationDelta = results.effectiveRate / 120;

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  });

  const formatCurrency = costDollars => {
    return currencyFormatter.format(costDollars).replace('.00', '');
  };

  const animateAmount = useCallback(
    min => {
      setTimeout(() => {
        setAnimatedAmount(prevState => {
          if (prevState - animationDelta > min) {
            return effectiveRateHasDecimal
              ? prevState - animationDelta
              : Math.ceil(prevState - animationDelta);
          }

          setAnimationDone(true);

          return min;
        });
      }, 1);
    },
    [animationDelta, effectiveRateHasDecimal]
  );

  if (!animationDone) {
    animateAmount(results.effectiveRate);
  }

  return (
    <div className={`report-wrapper${animationDone ? ' show-body' : ''}`}>
      <section className={`hero${animationDone ? ' raise' : ''}`}>
        <h4>Effective Rate*</h4>
        <p className={`amount${animationDone ? ' pop' : ''}`}>
          {formatCurrency(animatedAmount)}
          <small>/month</small>
        </p>
      </section>
      <section className="result-cards">
        <div className="result-card">
          <h5>Products Selected</h5>
          <hr />
          <ul>
            <li>
              <strong>
                {data.coreSubscription.quantity > 1 && (
                  <>{data.coreSubscription.quantity}x </>
                )}
                {data.coreSubscription.heading.replace(
                  / (?:Monthly|Annually).*$/,
                  ''
                )}
              </strong>{' '}
              {isMonthlyPaymentTerm ? '(monthly)' : '(annually)'}{' '}
              <strong className="price">
                {formatCurrency(
                  data.coreSubscription.costDollars *
                    data.coreSubscription.quantity
                )}
              </strong>
            </li>
          </ul>
        </div>
        {data.paidAddOns.length > 0 && (
          <div className="result-card">
            <h5>Add-Ons Selected</h5>
            <hr />
            <ul>
              {data.paidAddOns.map(addOn => (
                <li key={addOn.heading}>
                  <strong>
                    {addOn.quantity > 1 && <>{addOn.quantity}x </>}
                    {addOn.heading.replace(/ (?:Monthly|Annually|\().*$/, '')}
                  </strong>{' '}
                  {addOn.implementation
                    ? '(one-time)'
                    : isMonthlyPaymentTerm
                    ? '(monthly)'
                    : '(annually)'}{' '}
                  <strong className="price">
                    {addOn.earlyAdopterPricing
                      ? '6x50'
                      : formatCurrency(addOn.costDollars * addOn.quantity)}
                  </strong>
                </li>
              ))}
            </ul>
          </div>
        )}
        {data.freeAddOns.length > 0 && (
          <div className="result-card">
            <h5>Free Add-Ons Given</h5>
            <hr />
            <ul>
              {data.freeAddOns.map(addOn => (
                <li key={addOn.heading}>
                  <strong>
                    {addOn.quantity > 1 && <>{addOn.quantity}x </>}
                    {addOn.heading.replace(/ (?:Free|\().*$/, '')}
                  </strong>{' '}
                  {addOn.implementation
                    ? '(one-time)'
                    : isMonthlyPaymentTerm
                    ? '(monthly) '
                    : '(annually) '}
                  <strong className="price">
                    <s>{formatCurrency(addOn.costDollars * addOn.quantity)}</s>
                  </strong>
                </li>
              ))}
            </ul>
          </div>
        )}
      </section>
      <section>
        <ul className="result-breakdown">
          {results.annualizedSavings > 0 && (
            <li>
              <div className="label">Annualized Savings</div>
              <div className="value">
                {formatCurrency(results.annualizedSavings)}
              </div>
            </li>
          )}
          <li>
            <div className="label">Due Today</div>
            <div className="value">{formatCurrency(results.dueToday)}</div>
          </li>
        </ul>
      </section>
      <div className="restart-container">
        <CallToAction
          className="restart"
          customHandleClick={onRestart}
          size="md"
          value="Restart Calculator"
          variant="tertiary"
        />
      </div>
    </div>
  );
};

export default Report;
